.team-container {
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.team-container p {
    text-align: center;
}

.team-display {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 2rem;
    margin-top: 2rem;
}

.team-display p {
    color: $text-dark-blue;
}

.team-display img {
    width: 100%;
    height: 100%;
}

@media (max-width: 992px) { 
    .team-display {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto;
        row-gap: 2rem;
    }
}

@media (max-width: 576px) { 
    .team-display {
        grid-template-columns: repeat(1, 1fr);
    }
}