$main-red: #AF272F;
$dark-red: #220406;
$text-grey: #595959;
$text-red: #B80606;
$text-dark-blue: #00092C;
$text-orange: #FE7146;

$header-blue: #0071D8;
$header-green: #00D879;

$carrer-green: #2BC155;
$carrer-yellow: #FBA556;
$carrer-purple: #BF58D9;
$carrer-blue: #3BB2F4;


$default-font-family: 'Noto Sans', sans-serif;

$standardFontSize: 1rem;
$formFontSize: 1 * $standardFontSize;
$pageTitleFontSize: 3 * $standardFontSize;
$pageBreadcrumbsFontSize: 1 * $standardFontSize;
$footerPhoneFontSize: 1.5 * $standardFontSize;
$mobileButtonFontSize: 0.9 * $standardFontSize;
$mobileNavLinkFontSize: 1 * $standardFontSize;
$headerTitleFontSize: 2.75 * $standardFontSize;
// $headerTitleFontSize: 2.75 * $standardFontSize;
$bigSectionHeader: 3 * $standardFontSize;
$mobileBigSectionHeader: 2.25 * $standardFontSize;
$sectionHeader: 2.5 * $standardFontSize;
$mobileSectionHeader: 2 * $standardFontSize;


//body
$defaultFontSize: 1rem;
$defaultLineHeight: normal;