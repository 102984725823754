@import 'variables';

header {
    background-color: #FFF;
    z-index: 100;
}

.navigation {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-auto-flow: column;
    padding-top: .5rem;
    padding-bottom: .5rem;
    font-size: $standardFontSize;
}

.navigation-fixed {
    position: fixed;
    width: 100% !important;
}

.navigation-left {
    display: flex;
    align-items: center;
}

.navigation-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.navigation-desktop {
    width: 13rem;
}

.navigation-logo {
    width: 250px;
    height: 50px;
    transition: all .3s ease;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
}

.navigation-logo:hover {
    transform: translateX(3px);
    -webkit-transform: translateX(3px);
    -moz-transform: translateX(3px);
    -ms-transform: translateX(3px);
    -o-transform: translateX(3px);
}

.navigation-list-desktop {
    text-decoration: none;
    list-style-type: none;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
}

.navigation-list-mobile {
    list-style-type: none;
    text-decoration: none;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-auto-flow: row;
    row-gap: 2.5rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.navigation-mobile-container {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
}

.navigation-mobile-clickable {
    position: absolute;
    cursor: pointer;
    right: 0;
    background-color: rgba($color: #000000, $alpha: .9);
    width: 100%;
    height: 100%;
}

.navigation-mobile-menu {
    position: absolute;
    display: flex;
    flex-direction: column;
    right: -50%;
    width: 50%;
    height: 100%;
    padding: .5rem 0.938rem;
    background-color: #FFF;
}

.navigation-link {
    color: $text-grey;
    padding: 1rem;
    transition: all .3s ease;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
}

.navigation-link:hover {
    color: #000;
}

.active-link {
    font-weight: 700;
}

.navigation-price-button {
    @extend .red-button;
    display: flex;
    padding: .5rem 1rem;
    justify-content: space-between;
    border: 0;
}

.navigation-show-button {
    background: #FFF;
    border: 0;
    padding: .5rem;
    border-radius: 25%;
    cursor: pointer;
}

.navigation-hide-button {
    @extend .navigation-show-button;
    margin-left: auto;
}

@media (max-width: 1200px) {
    .navigation-link {
        font-size: $mobileNavLinkFontSize;
        padding: 1rem 2rem 1rem 0;
    }
}

@media (max-width: 991.98px) { 
    .navigation-list-desktop {
        display: none;
    }
    .navigation-desktop {
        display: none;
    }
    .navigation-logo {
        width: 170px;
        height: auto;
        max-width: 100%;
    }
    .navigation-price-button {
        margin-top: 2rem;
        width: 100%;
        margin-left: auto;
        font-size: $mobileButtonFontSize;
    }
}

@media (min-width: 991.98px) { 
    .navigation-list-mobile {
        display: none;
    }
    .navigation-mobile {
        display: none;
    }
}

