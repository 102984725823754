.header-swiper {
  position: relative;
  width: 100%;
  height: 100%;
}

.header-swiper .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.header-swiper-pagination {
  display: flex;
  justify-content: center;
}

.pagination-rectangle.swiper-pagination-bullet {
  display: inline-block;
  width: 25%;
  height: 3px;
  margin: 0rem 0.5rem;
  background-color: rgba($color: #fff, $alpha: 1);
  opacity: 1;
  border-radius: 0;
}

.pagination-rectangle.swiper-pagination-bullet-active {
  background-color: $main-red;
}

.header-swiper-background-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 2rem;
  color: #fff;
  background-color: rgba($color: #000000, $alpha: 0.5);
  position: absolute;
  bottom: 0;
  z-index: 1;
  width: 50%;
  height: 11%;
}

.header-swiper-text-upper {
  color: #A4A4A4;
}

.header-swiper-text-lower { 
  font-size: 1.375rem;
  font-weight: 700;
}

.header-swiper-background-right {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
  width: 50%;
  height: 11%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba($color: #000000, $alpha: 0.5);
}
