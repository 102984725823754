.contact-page-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 2rem;
}

.contact-page-container iframe {
    width: 100%;
    height: 100%;
    min-height: 300px;
    border: 0;
}

.form-buttons {
    display: flex;
    justify-content: space-evenly;
}

.contact-page-container input[type="reset"], .contact-page-container input[type="submit"] {
    @extend .red-button;
    border: 0;
    width: 30%;
    padding: 1rem 0;
    font-size: 1rem;
    margin-top: 1rem;
    cursor: pointer;
}

@media (max-width: 992px) {
    .contact-page-container {
        display: flex;
        flex-direction: column-reverse;
        row-gap: 2rem;
    }
}