.services-choice-container {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.services-choice-container .section-header {
  text-align: center;
}

.services-main {
  display: flex;
  flex-direction: column;
}

.services-selects {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  column-gap: 1rem;
  margin: 3rem 0;
}

.services-select {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  background-color: #f5f5fd;
  aspect-ratio: 1 / 1;
  padding: .5rem;
  width: 100%;
  height: 100%;
}

.services-select .active-services-select-arrow {
  display: none;
}

.services-select svg {
  width: 50px;
  height: 50px;
}

.services-select svg .red {
  color: #AF272F;
}

.services-select svg .black {
  color: #231F20;
}

.services-select-text {
  text-align: center;
  color: #555555;
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.75rem;
}

.active-services-select {
  background-color: $main-red;
  box-shadow: 0px 16px 20px -13px rgba(194, 99, 70, 0.7);
}

.active-services-select .services-select-text,
.active-services-select svg {
  color: #fff;
}

.active-services-select svg .black, .active-services-select svg .red {
  color: inherit;
}

.active-services-select .active-services-select-arrow {
  display: block;
  position: absolute;
  top: 85%;
  bottom: 0;
  color: $main-red;
}

.service-chosen-display {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 3rem;
  margin: 2rem;
}

.service-chosen-display img {
  width: 80%;
  margin: auto;
}

.service-chosen-infos {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.service-chosen-title {
  font-size: 2rem;
  font-weight: 700;
  color: $text-dark-blue;
}

.service-chosen-text {
  font-size: 1.125rem;
  color: $text-grey;
  line-height: 1.75rem;
  margin: 2rem 0;
}

.service-chosen-details-button {
  @extend .red-button;
  display: flex;
  justify-content: space-evenly;
  padding: 1rem;
  width: 40%;
}

@media (max-width: 1200px) {
  .services-selects {
    column-gap: .8rem;
  }
  .service-chosen-details-button {
    width: 60%;
  }
  .services-select-text {
    font-size: 1rem;
  }
}

@media (max-width: 992px) {
  .services-selects {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    row-gap: 1rem;
    column-gap: 1rem;
    cursor: pointer;
  }
  .services-select {
    aspect-ratio: auto;
    padding: 1rem;
  }

  .active-services-select .active-services-select-arrow {
    display: none;
  }
  .services-select svg {
    display: none;
  }
  .service-chosen-display {
    display: flex;
  }
  .service-chosen-display img {
    display: none;
  }
  .service-chosen-infos {
    align-items: center;
    text-align: center;
  }
}

@media (max-width: 576px) {
    .services-selects {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(3, 1fr);
      row-gap: 1rem;
      column-gap: .5rem;
    }
    .services-select {
        padding: .5rem;
    }
  }