@import 'variables';

.footer-container {
    background-color: $dark-red;
    color: #FFF;
    display: flex;
    flex-direction: column;
    padding-top: 3rem;
    font-size: $standardFontSize;
}

.footer-infos {
    display: flex;
    justify-content: space-between;
}

.footer-clauses {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0rem;
}

.infos-header {
    font-size: $standardFontSize;
    font-weight: 700;
    margin-bottom: 1rem;
}

.infos-element {
    margin: 2rem 0rem;
}

.infos-element a {
    padding: 1rem 1rem 1rem 0;
}

.icon-text {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    align-items: center;
}

.icon-text p {
    grid-column: 2;
    grid-column: span 9;
}

.icon-text.icon-phone-text p {
    padding-left: 15px;
}

.number-icons-div .icon-text p {
    line-height: 1.75rem;
}

.phone-number {
    font-size: $footerPhoneFontSize;
    font-weight: 700;
}

.social-media-buttons {
    display: flex;
    justify-content: flex-start;
    margin-top: 1.5rem;
}

.social-media-buttons a {
    margin-right: 0.5rem;
}

.footer-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    color: #FFF;
    border: .1rem solid rgba($color: #FFF, $alpha: .2);
    padding: .5rem;
    width: 48px;
    height: 48px;
    cursor: pointer;
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    -o-border-radius: 100%;
}

.footer-line {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid rgba($color: #FFF, $alpha: .2);
    margin: 1em 0;
    padding: 0;
}

.clauses-links {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 2rem;
    text-align: center
}

.number-icons-div {
    display: flex;
    flex-direction: column;
}

.footer-logo {
    @extend .navigation-logo;
}

@media (max-width: 991.98px) { 
    .footer-container {
        padding-top: 2rem;
        padding-bottom: 1rem;
    }
    .footer-infos {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 90%;
    }
    .infos-header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .footer-clauses {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }
    .clauses-links {
        grid-auto-flow: row;
        grid-template-columns: none;
        row-gap: 1rem;
        column-gap: 0;
    }
    .phone-number {
        text-align: end;
    }
    .social-media-buttons {
        margin-bottom: 2rem;
    }
    .number-icons-div {
        order: -1;
    }
    .footer-logo {
        @extend .navigation-logo;
        margin: 2rem 0rem;
    }
}