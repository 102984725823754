.choose-us-container {
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.choose-us-header {
    text-align: center;
    margin-bottom: 1rem;
}

.choose-us-subheader {
    font-size: 1.125rem;
    color: $text-grey;
    text-align: center;
}

.choose-us-cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 5rem;
    margin: 3rem 0;
}

.choose-us-card {
    padding: 1.5rem;
    transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
}

.hovered-card {
    box-shadow: 0px 19px 29px rgba(62, 53, 120, 0.14);
}

.card-icon {
    width: 81px;
    height: 81px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card-icon-red {
    @extend .card-icon;
    background-color: $main-red;
    padding: 1rem;
}

.card-icon-purple {
    @extend .card-icon;
    background-color: $carrer-purple;
    padding: 1rem;
}

.card-icon-blue {
    @extend .card-icon;
    background-color: $carrer-blue;
    padding: 1rem;
}

.card-title {
    font-size: 1.875rem;
    color: #363848;
    margin: 1rem 0;
    font-weight: 600;
}

.card-text {
    line-height: 1.75rem;
}

@media (max-width: 992px) {
    .choose-us-cards {
        grid-template-columns: repeat(1, 1fr);
        row-gap: 2rem;
    }
    .card-icon {
        margin-left: auto;
        margin-right: auto;
    }
    .card-title, .card-text {
        text-align: center;
    }
}