.about-container {
    padding-top: 3rem;
    padding-bottom: 3rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 4rem;
}

.about-image {
    display: flex;
    align-items: center;
    justify-content: center;
}

.about-container img {
    width: 80%;
}

.big-section-header {
    font-size: $bigSectionHeader;
    color: $text-dark-blue;
    font-weight: 700;
}

.about-infos {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.about-text {
    margin: 2rem 0;
    line-height: 1.75rem;
}

.about-text span {
    font-weight: 700;
}

.learn-more-button {
    @extend .red-button;
    display: flex;
    padding: 1rem;
    justify-content: space-between;
    border: 0;
    width: 40%;
}

@media (max-width: 1400px) {
    .learn-more-button {
        width: 70%;
    }
}

@media (max-width: 992px) { 
    .about-container {
        grid-template-columns: none;
        grid-template-rows: auto;
        grid-auto-flow: dense;
        row-gap: 3rem;
    }
    .about-container img {
        width: 60%;
    }
    .big-section-header {
        font-size: $mobileBigSectionHeader;
    }
    .learn-more-button {
        display: none;
    }
}
