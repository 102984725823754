.header-container {
  display: flex;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  padding-left: 2rem;
  border-left: 2px solid #cdcdcd;
}

.header-left,
.header-right {
  width: 50%;
}

.header-left {
  overflow: hidden;
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-auto-flow: row;
  row-gap: 2rem;
  margin-right: 2rem;
}

.header-left-text {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.header-subtitle {
  color: #9e9e9e;
  font-size: 1.25rem;
  font-weight: 800;
}

.header-title {
  font-size: $headerTitleFontSize;
  font-weight: 800;
  margin-top: 0.5rem;
  margin-bottom: 3rem;
}

.header-red-button {
  @extend .red-button;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 1.25rem;
  width: 50%;
  padding: 1rem;
}

.services-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 0.3rem;
  align-items: end;
}

.service-red,
.service-blue,
.service-green {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: fit-content;
  padding: 1rem 1rem;
  background-color: #fbfbfb;
}

.service-header {
  color: #151515;
  font-size: 1.438rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.service-text {
  color: #5e5e5e;
  line-height: 1.5rem;
}

.service-red svg,
.service-blue svg,
.service-green svg {
  margin-right: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.service-button {
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem;
  margin-left: auto;
}

.service-button svg {
  margin: 0;
}

.service-red {
  border-top: 4px solid $main-red;
}

.service-red .service-button {
  border: 2px solid $main-red;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
}

.service-red svg {
  color: $main-red;
}

.service-blue {
  border-top: 4px solid $header-blue;
}

.service-blue .service-button {
  border: 2px solid $header-blue;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
}

.service-blue svg {
  color: $header-blue;
}

.service-green {
  border-top: 4px solid $header-green;
}

.service-green .service-button {
  border: 2px solid $header-green;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
}

.service-green svg {
  color: $header-green;
}

@media (max-width: 576px) {
  .header-container {
    width: 100%;
    padding: 0;
  }
  .header-red-button {
    width: 75%;
  }
  .service-header {
    font-size: 1.25rem;
  }
}

@media (min-width: 576px) {
  .header-container {
    width: 540px;
    padding-right: 2rem;
  }
}

@media (min-width: 768px) {
  .header-container {
    width: 720px;
  }
}

@media (max-width: 992px) {
  .header-title {
    font-size: 2.625rem;
    font-weight: 700;
  }
  .header-subtitle {
    font-size: 1rem;
    font-weight: 700;
  }
  .header-title span {
    display: none;
  }
  .services-container {
    display: flex;
    column-gap: 0;
  }
  .service-red,
  .service-blue,
  .service-green {
    justify-content: space-around;
    height: 100%;
  }
}

@media (min-width: 992px) {
  .header-container {
    width: 980px;
  }
}

@media (max-width: 1200px) {
  .header-container {
    display: flex;
    flex-direction: column-reverse;
    justify-content: normal;
    height: auto;
    padding-bottom: 3rem;
    margin-bottom: 3rem;
    border-right: 2px solid #cdcdcd;
  }
  .header-left,
  .header-right {
    width: 100%;
  }
  .header-left {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
  }
  .header-left-text {
    align-items: center;
    text-align: center;
  }
  .header-right img {
    width: 100%;
    height: 300px;
    object-fit: cover;
    object-position: center 50%;
  }
  .services-container {
    margin: 0;
  }
}

@media (min-width: 1200px) {
  .header-container {
    width: 1180px;
    padding-right: 0;
  }
}

@media (min-width: 1400px) {
  .header-container {
    width: 1920px;
    max-width: 100%;
  }
}
