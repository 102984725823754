.steps-container {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.steps-display {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 3rem;
  margin: 1rem 0;
}

.steps-buttons {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.steps-button {
  padding: 1rem;
  margin: 1rem 0;
  transition: all .3s ease-in-out;
  box-shadow: 0px 7px 22px rgba(143, 134, 196, 0.07);
}

.active-step-button {
  box-shadow: 0px 19px 29px rgba(62, 53, 120, 0.14);
}

.step-text {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 1.5rem;
}

.step-number {
  display: flex;
  margin-right: 2rem;
  justify-content: center;
  align-items: center;
  font-size: 2.25rem;
  font-weight: 600;
  width: 81px;
  height: 81px;
}

.step-number-purple {
  @extend .step-number;
  color: #8e22e2;
  background-color: #f6ebff;
}

.step-number-blue {
  @extend .step-number;
  color: #2b3dc7;
  background-color: #e7e9ff;
}

.step-number-green {
  @extend .step-number;
  color: #3dcd65;
  background-color: #e9ffef;
}

.step-info {
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding: 1rem 2rem;
  box-shadow: 0px 3px 20px rgba(62, 53, 120, 0.04);
  color: $text-dark-blue;
}

.info-title {
  font-size: 1.5rem;
  font-weight: 600;
  margin: 1rem 0;
}

.info-text {
  margin-bottom: 1rem;
  line-height: 1.75rem;
}

@media (max-width: 1200px) {
  .steps-display {
    column-gap: 2rem;
  }
  .step-number {
    margin-right: 1rem;
  }
}

@media (max-width: 992px) {
  .steps-display {
    display: flex;
    column-gap: 0;
  }
  .step-number {
    margin: 0;
  }
}

@media (max-width: 576px) {
  .steps-display {
    flex-direction: column;
  }
  .steps-buttons {
    flex-direction: row;
  }
  .step-number {
    width: 70px;
    height: 70px;
  }
}
