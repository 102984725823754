.possibilities-container {
    padding-top: 3rem;
    padding-bottom: 3rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 1rem;
}

.possibilities-container img {
    width: 100%;
    height: 100%;
}

.possibilities-infos {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.possibilities-infos-upper {
    display:  flex;
    flex-direction: column;
    margin-bottom: 2rem;
}

.possibilities-infos-lower {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 1rem;
}

.possibilities-infos-subheader {
    font-size: 1.625rem;
    color: $text-dark-blue;
    font-weight: 700;
}

.possibilities-infos-text {
    font-size: 1.25rem;
    line-height: 1.75rem;
    color: $text-grey;
    margin-top: 1rem;
}

@media (max-width: 1200px) {
    .possibilities-container {
        display: flex;
        flex-direction: column;
        text-align: center;
    }
    .possibilities-infos {
        margin-bottom: 1rem;
    }
}

@media (max-width: 992px) {
    .possibilities-infos-lower {
        display: grid;
        grid-template-columns: none;
        grid-template-rows: repeat(2, 1fr);
        row-gap: 1rem;
    }
}