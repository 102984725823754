.workflow-container {
    padding-top: 3rem;
    padding-bottom: 3rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 3rem;
}

.workflow-infos {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 3rem 3rem 3rem 0
}

.workflow-text {
    font-size: 1.25rem;
    line-height: 1.75rem;
    color: $text-grey;
}

.workflow-red-button {
    @extend .red-button;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 1.25rem;
    width: 60%;
    padding: 1rem;
    margin-top: 1rem;
}

.stages-display {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    column-gap: 2rem;
    row-gap: 2rem;
}

.stage-number {
    color: #B80606;
    opacity: 0.3;
    font-size: 4rem;
    font-weight: 700;
}

.stage-title {
    color: $text-dark-blue;
    font-size: 1.75rem;
    font-weight: 700;
    margin: 1rem 0;
}

.stage-text {
    color: $text-grey;
    font-size: 1.25rem;
    line-height: 1.75rem;
}

@media (max-width: 992px) {
    .workflow-container {
        display: flex;
        flex-direction: column;
    }
    .workflow-infos {
        padding: 1rem 0
    }
    .workflow-infos > * {
        margin: 1rem 0;
    }
}
@media (max-width: 576px) {
    .stages-display {
        grid-template-columns: none;
        grid-template-rows: repeat(4, 1fr);
        row-gap: 1rem;
    }
    .workflow-red-button {
        width: 80%;
    }
    .workflow-text, .stage-text {
        font-size: 1rem;
    }
}