.member-links-container {
    display: flex;
    justify-content: space-between;
    position: relative;
}

.member-photo {
    width: 100%;
}

.member-links {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #00092C, $alpha: .8);
    display: flex;
    align-items: center;
    justify-content: center;
}

.member-link {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 .5rem;
    width: 48px;
    height: 48px;
    color: #555555;
    background-color: #FFF;
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    -o-border-radius: 100%;
}

.member-name {
    font-size: 1.5rem;
    font-weight: 700;
    margin-top: 1rem;
    margin-bottom: .5rem;
}

@media (max-width: 992px) {
    .member-link {
        margin: 0 .2rem;
    }
  }