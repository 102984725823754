.pros-container {
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.pros-container ul {
    list-style: none;
}

.pros-container li {
    font-size: 1.125rem;
    color: $text-dark-blue;
    font-weight: 600;
    margin: 1rem 0;
}

.pros-container ul li::before {
    content: '✓';
    color: $main-red;
    margin-right: 1rem;
}

.pros-upper {
    display: grid;
    grid-template-columns: 1fr 3fr;
}

.pros-upper-card {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-right: 3rem;
    padding: 3rem;
    line-height: 2rem;
    color: #555555;
    font-weight: 700;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
}

.pros-upper-card-title {
    font-size: 1.25rem;
}

.pros-upper-card-text {
    font-size: 1.5rem;
    margin: 2rem 0;
}

.pros-upper-card-text span {
    color: $main-red;
}

.pros-button {
    @extend .red-button;
    display: flex;
    justify-content: space-evenly;
    padding: .5rem 0;
}

.pros-upper-infos {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.pros-upper-infos-header {
    color: $text-dark-blue;
    font-size: 2rem;
    font-weight: 700;
}

.pros-upper-infos-text {
    color: $text-grey;
    font-size: 1.25rem;
    margin: 1rem 0;
}

.pros-lower {
    margin-top: 3rem;
}

.pros-lower img {
    width: 250px;
    height: 250px;
}

.pros-lower .section-header {
    text-align: center;
}

.pros-lower-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    column-gap: 3rem;
    row-gap: 3rem;
    margin-top: 3rem;
}

.pros-lower-card-image {
    display: flex;
    justify-content: center;
    align-items: center;
}

.pros-lower-card-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba($main-red, 0.25);
    color: $main-red;
    width: 70px;
    height: 70px;
}

.pros-lower-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 2rem;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
}

.pros-lower-card-title {
    font-size: 1.5rem;
    color: $text-dark-blue;
    font-weight: 700;
    margin: 1rem 0;
}

.pros-lower-card-text {
    font-size: 1.125rem;
    color: $text-grey;
    line-height: 1.75rem;
}

.middle-card {
    grid-column: 2;
    grid-row: 1 / span 2;
}

.middle-card .pros-lower-card-text {
    margin-bottom: 2rem;
}

.middle-card ul {
    margin: 3rem 0;
}

.middle-card li {
    text-align: start;
}

.middle-card .pros-button {
    width: 80%;
    padding: 1rem 0;
}

@media (max-width: 1200px) {
    .pros-upper {
        display: flex;
        flex-direction: column-reverse;
    }
    .pros-upper-card {
        margin: auto;
        margin-top: 2rem;
        text-align: center;
        align-items: center;
        width: 75%;
    }
    .pros-upper-card .pros-button {
        width: 50%;
    }
    .pros-lower-card-image {
        display: none;
    }
    .pros-lower-grid {
        display: flex;
        flex-direction: column;
        width: 75%;
        margin: auto;
    }
}

@media (max-width: 992px) {
    .pros-upper-card {
        width: 100%;
        padding: 1rem;
    }
    .middle-card .pros-lower-card-text {
        margin: 0;
    }
    .pros-lower-grid {
        width: 100%;
    }
}