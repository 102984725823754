.posts-container {
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.posts-container .section-header {
    margin-bottom: 2rem;
    text-align: center;
}

.posts {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 4rem;
}

.post {
    display: flex;
    flex-direction: column;
}

.post img {
    width: 100%;
}

.post .post-date {
    color: $main-red;
    align-self: flex-end;
    font-size: 1.25rem;
}

.post .post-title {
    color: $text-dark-blue;
    font-size: 2rem;
    font-weight: 700;
    margin: 1rem 0;
}

.post .post-link {
    color: $text-grey;
    align-self: flex-end;
    font-size: 1.25rem;
}

@media (max-width: 992px) {
    .posts {
        grid-template-columns: repeat(1, 1fr);
        row-gap: 2rem;
    }    
}