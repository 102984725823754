.modal-background {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: .9);
    z-index: 1024;
}

.modal {
    width: 50%;
    height: 50%;
    background-color: #FFF;
    cursor: default;
}