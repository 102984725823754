.stats-background {
    margin-top: 3rem;
    margin-bottom: 3rem;
    background: #FAFAFA;
    padding: 3rem 0;
}

.stats-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 1rem;
}

.default-stat-display {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    text-align: center;
    padding: 2rem;
    background-color: #FFF;
    box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.05);
    transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
}

.stat-icon svg {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    color: #B20006;
}

.stat-number {
    margin: .5rem 0;
    font-size: 2.25rem;
    font-weight: 700;
    color: $text-dark-blue;
}

.stat-text {
    font-size: 1.25rem;
    line-height: 2rem;
    color: $text-grey;
}

.active-stat-display {
    background-color: #B20006;
}

.active-stat-display .stat-icon svg, .active-stat-display .stat-number, .active-stat-display .stat-text {
    color: #FFF;
}

@media (max-width: 992px) {
    .stats-container {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 1rem;
        row-gap: 1rem;
    } 
}

@media (max-width: 576px) {
    .stats-container {
        grid-template-columns: repeat(1, 1fr);
    } 
}