.realizations-container {
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.realizations-container p {
    text-align: center;
}

.realization-category-select {
    margin-top: 2rem;
    margin-bottom: 3rem;
    display: flex;
    justify-content: center;
}

.realization-category-select input {
    visibility: hidden;
}

.realization-category-select-label {
    cursor: pointer;
    display: block;
    width: 100%;
    color: $text-grey;
    padding: .8rem .5rem;
    border: 1px solid transparent;
    text-align: center;
}

.realization-category-select-underline {
    display: none;
}

.realization-category-select input:checked + label {
    position: relative;
    color: $text-dark-blue;
    border: 1px solid #E9E9E9;
    width: 100%;
}

.realization-category-select input:checked + label .realization-category-select-underline {
    display: block;
    position: absolute;
    height: 3px;
    width: 50%;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    background: #B20006;
}

.realizations-display {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    column-gap: 4rem;
    row-gap: 2rem;
    margin-top: 2rem;
}

.realizations-red-button {
    @extend .red-button;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 1.25rem;
    width: 30%;
    padding: 1rem;
    margin-top: 3rem;
    margin-left: auto;
    margin-right: auto;
}


@media (max-width: 1200px) {
    .realizations-display {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 2rem;
        row-gap: 2rem;
    }
}

@media (max-width: 992px) {
    .realizations-red-button {
        width: 70%;
    }
}

@media (max-width: 768px) {
    .realization-category-select {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        row-gap: 1rem;
        column-gap: 1rem;
        justify-content: stretch;
    }
}

@media (max-width: 576px) { 
    .realizations-display {
        grid-template-columns: repeat(1, 1fr);
    }
    .realizations-red-button {
        width: 100%;
    }
}