.realization-links-container {
    display: flex;
    justify-content: space-between;
    position: relative;
}

.realization-photo {
    width: 100%;
    aspect-ratio: 1 / 1;
}

.realization-photo img {
    max-width: 100%;
    border: 1px solid $text-grey;
    border-radius: 25px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
}

.realization-links {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #00092C, $alpha: .8);
    display: flex;
    align-items: center;
    justify-content: center;
}

.realization-link {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    cursor: pointer;
    width: 80px;
    height: 80px;
    color: #FFF;
    background-color: $main-red;
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    -o-border-radius: 100%;
}

.realization-link svg {
    height: 1.5rem;
}

.realization-info p {
    text-align: left;
    word-break: break-word;
    margin-top: 1rem;
    margin-left: 2rem;
}
.realization-info span {
    display: inline-block;
}

.realization-tags {
    color: $main-red;
}

.realization-name {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: .5rem;
    color: $text-dark-blue;
}

@media (max-width: 992px) {
    .realization-link {
        width: 60px;
        height: 60px;
    }
    .realization-info p {
        margin-top: .5rem;
        margin-left: .5rem;
    }
}