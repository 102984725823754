.contact-container {
    padding-top: 3rem;
    padding-bottom: 6rem;
}

.contact-button-container {
    display: flex;
    justify-content: center;
}

.contact-baner {
    margin-top: 4rem;
    background-color: $main-red;
    padding: 8rem 4rem;
    color: #FFF;
}

.contact-header {
    font-size: 2.25rem;
    font-weight: 700;
}

.contact-text {
    margin: 1rem 0;
}

.contact-white-button {
    display: flex;
    padding: .8rem 1rem;
    justify-content: space-evenly;
    border: 0;
    background-color: #FFF;
    color: $main-red;
    width: 25%;
}

.contact-red-button {
    @extend .red-button;
    display: flex;
    padding: .8rem 1rem;
    justify-content: space-evenly;
    border: 0;
    width: 20%;
}

@media (max-width: 992px) {
    .contact-baner {
        padding: 4rem 2rem;
    }
    .contact-header {
        font-size: 1.5rem;
    }
    .contact-white-button {
        width: 40%;
    }
    .contact-red-button {
        width: 50%;
    }
}

@media (max-width: 576px) {
    .contact-baner {
        padding: 2rem 1rem;
    }
    .contact-white-button {
        width: 70%;
    }
    .contact-red-button {
        width: 80%;
    }
}
