.projects-container {
    padding-top: 3rem;
}

.projects-container .section-header {
    margin-bottom: 2rem;
}

.projects-display-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 2rem;
}

.projects-display-container div {
    z-index: -1;
}

@media (max-width: 1200px) {
    .projects-display-container {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 2rem;
        row-gap: 2rem;
    }
}


@media (max-width: 576px) { 
    .projects-display-container  {
        grid-template-columns: repeat(1, 1fr);
    }
}