.htw-container {
    margin-top: 3rem;
    margin-bottom: 3rem;
    padding-top: 3rem;
    padding-bottom: 3rem;
    background-color: #FAFAFA;
}

.htw-header {
    font-size: $sectionHeader;
    font-weight: 700;
    text-align: center;
}

.htw-steps-display {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    column-gap: 1rem;
    row-gap: 2rem;
    margin-top: 2rem;
}

.htw-step-line {
    width: 48px;
    height: 3px;
    background-color: $main-red;
    margin-bottom: 1rem;
}

.htw-step-number {
    font-size: 4rem;
    color: $main-red;
    opacity: .3;
    font-weight: 700;
}

.htw-step-title {
    font-size: 1.375rem;
    color: $text-dark-blue;
    font-weight: 700;
    margin: 1rem 0;
}

.htw-step-text {
    color: $text-grey;
    font-size: 1.125rem;
    line-height: 1.75rem;
}

.htw-step-text span {
    color: $main-red;
}

@media (max-width: 992px) {
    .htw-steps-display {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(4, 1fr);
    }
}

@media (max-width: 576px) {
    .htw-steps-display {
        grid-template-columns: none;
        grid-template-rows: repeat(8, 1fr);
    }
}