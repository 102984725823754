.about-service-container {
    padding-top: 3rem;
}

.about-service-container .section-header {
    margin-bottom: 2rem;
}

.about-service-text {
    font-size: 1.2rem;
    line-height: 2rem;
}