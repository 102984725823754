.not-found-container {
    margin: 5rem 0;
    text-align: center;
}

.not-found-container p:first-child {
    color: $text-dark-blue;
    font-weight: 700;
    font-size: 3rem;
}

.not-found-container p:not(:first-of-type) {
    color: $text-grey;
    margin-top: 1rem;
    font-size: 1.5rem;
}