.offers-container {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.offers-header {
  display: flex;
  justify-content: space-between;
}

.offers-filters {
  display: flex;
  align-items: center;
  font-weight: 600;
  color: $text-dark-blue;
}

.offers-filters input[type=radio], .offers-filters input[type=checkbox] {
  display: none;
}

.offers-filters .full-time-label, .offers-filters .freelance-label {
  display: grid;
  align-items: center;
  cursor: pointer;
  grid-template-columns: 2rem auto;
  margin-right: .5rem;
}

.salary-label {
  display: flex;
  align-items: center;
}

.salary-label .checkbox-mark-background {
  position: relative;
  margin-left: .5rem;
  cursor: pointer;
  width: 60px;
  height: 20px;
  background-color: #969696;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
}

.salary-label .checkbox-mark {
  position: absolute;
  top: -25%;
  height: 30px;
  aspect-ratio: 1 / 1;
  background-color: #2E2E2E;
  transform: translateX(0%);
  -webkit-transform: translateX(0%);
  -moz-transform: translateX(0%);
  -ms-transform: translateX(0%);
  -o-transform: translateX(0%);
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  transition: all .3s ease-in-out;
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -ms-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
}

.salary-label .checkbox-mark-background.active {
  background-color: rgba($color: $main-red, $alpha: .4);
}

.salary-label .checkbox-mark.active {
  background-color: $main-red;
  transition: all .3s ease-in-out;
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -ms-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  transform: translateX(100%);
  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  -ms-transform: translateX(100%);
  -o-transform: translateX(100%);
}

.offers-display {
  display: grid;
  grid-template-rows: auto;
  grid-auto-flow: row;
  row-gap: 3rem;
  margin: 3rem;
}

.offer-display {
  display: grid;
  grid-template-columns: 3fr 3fr 3fr 1fr;
  align-items: center;
  box-shadow: 0px 5px 12px rgba(62, 53, 120, 0.07);
  padding: 1.5rem;
}

.offer-title {
  font-size: 1.5rem;
  font-weight: 600;
}

.offer-position {
  @extend .section-header-red;
}

.text-icon {
  display: flex;
  align-items: center;
}

.category-name {
    color: $text-grey;
}

.category-value {
    font-weight: 500;
    color: #2E2E2E;
    margin-bottom: .3rem;
}

.dollar-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  width: 55px;
  height: 55px;
  margin-right: .5rem;
  background-color: $carrer-green;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
}

.clock-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  width: 55px;
  height: 55px;
  margin-right: .5rem;
  background-color: $carrer-yellow;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
}

.offer-button {
  @extend .red-button;
  justify-self: stretch;
  padding: 1rem 0;
  cursor: pointer;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

@media (max-width: 1200px) {
  .offers-display {
    margin-left: 0;
    margin-right: 0;
  }
  .offers-header {
    flex-direction: column;
    align-items: center;
  }
  .offers-filters {
    margin-top: 2rem;
  }
  .offers-filters .full-time-label, .offers-filters .freelance-label {
    margin-right: 1rem;
  }
}

@media (max-width: 992px) {
  .offer-display {
    grid-template-columns: repeat(4, 1fr);
  }
  .text-icon {
    height: 100%;
    flex-direction: column;
    justify-content: start;
    text-align: center;
  }
  .clock-icon, .dollar-icon {
    margin: 0;
    margin-bottom: .5rem;
  }
}

@media (max-width: 768px) {
  .offer-display {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 2rem;
  }
   .offer-button {
    grid-row: 1;
    grid-column: 2;
   }
}

@media (max-width: 576px) {
  .offer-button {
   margin: 0 .5rem;
  }
  .offers-filters {
   align-items: start;
   flex-direction: column;
   align-self: start;
  }
  .salary-label {
    flex-direction: row-reverse;
    margin-top: .5rem;
  }
  .offers-filters .full-time-label, .offers-filters .freelance-label {
    margin: .5rem 0;
  }
  .salary-label .checkbox-mark-background {
    margin-left: 0;
    margin-right: .5rem;
   
  }
}
