.page-title-section {
    padding: 6rem 0;
    background-color: $main-red;
    text-align: center;
    color: white;

    h1 {
        font-size: $pageTitleFontSize; 
        margin-bottom: 0;
    }


    ul.breadcrumbs {
        margin: 0 auto;
        list-style: none;
        display: flex;
        justify-content: center;
        li {
            font-size: $pageBreadcrumbsFontSize;
            &.active {
                font-weight: 600;
            }
            padding: .4rem 1rem;
            position: relative;
            &:not(:first-child)::before {
                content: '>';
                position: absolute;
                left: -.25rem;
            }
        }
    }
}