.contact-container {
    @extend .contact-page-container;
}

.contact-container .contact-infos {
    margin-top: 2rem;
    display: flex;
    color: $text-dark-blue;
    flex-direction: column;
    justify-content: space-between;
    font-size: 1.2rem;
}


.contact-container .contact-header {
    grid-column: span 2;
}

.contact-infos-button {
    @extend .red-button;
    display: flex;
    margin-top: 1rem;
    justify-content: space-between;
    border: 0;
    width: 50%;
    padding: 1rem;
    font-size: 1.25rem;
    align-self: center;
}

@media (max-width: 1200px) {
    .contact-infos-button {
        width: 70%;
    }
}

@media (max-width: 992px) {
    .contact-container {
        flex-direction: column;
    }
    .contact-container .contact-infos {
        order: 4;
        margin: 0;
    }
    .contact-infos-button {
        display: none;
    }
}