@import "navigation";
@import "footer";
@import "not-found";
@import "components/form/form";
@import "components/pagetitle";
@import "components/buttons";
@import "components/modals/main";
@import "components/modals/realizations";
@import "components/modals/offers";
@import "components/modals/agreement";

@import "components/main-page/about";
@import "components/main-page/team";
@import "components/main-page/member";
@import "components/main-page/realizations";
@import "components/main-page/realization";
@import "components/main-page/stats";
@import "components/main-page/header";
@import "components/main-page/header-swiper";
@import "components/main-page/service-swiper";
@import "components/main-page/workflow";
@import "components/main-page/clients";
@import "components/main-page/clients-swiper";
@import "components/main-page/contact";
@import "components/main-page/posts";

@import "components/htw-page/possibilities";
@import "components/htw-page/htw-section";
@import "components/htw-page/pros";
@import "components/about-page/services";
@import "components/carrer-page/offers";
@import "components/carrer-page/choose-us";
@import "components/carrer-page/steps";

@import "components/contact-page/main";

@import "components/services-pages/navigation";
@import "components/services-pages/about-service";
@import "components/services-pages/projects";
@import "components/services-pages/faq";
@import "components/services-pages/contact";

@import "components/wp-page/main";

@import url('https://fonts.cdnfonts.com/css/noto-sans');

body {
  font-family: $default-font-family;
  font-weight: 300;
  font-size: $defaultFontSize;
  line-height: $defaultLineHeight;
}

* {
  scroll-margin-top: 90px;
}

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

textarea {
  resize: none;
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.container.container-padding {
  padding: 2rem 0;
}

.section-header {
  color: $text-dark-blue;
  font-size: $sectionHeader;
  font-weight: 700;
}

.section-header-red {
  color: $text-red;
}

.bold-span {
  font-weight: 700;
}

@media (min-width: 576px) {
  .container {
    width: 540px;
  }
}

@media (max-width: 992px) {
  .section-header {
    font-size: $mobileSectionHeader;
  }
  .mobile-hide {
    display: none;
  }
  * {
    scroll-margin-top: 55px;
  }
}

@media (min-width: 768px) {
  .container {
    width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    width: 960px;
  }
  .mobile-break { 
    display: none;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1140px;
  }
}

@media (min-width: 1400px) {
  .container {
    width: 1320px;
  }
}




