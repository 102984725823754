.faq-container {
    padding-top: 3rem;
}

.faq-container .section-header {
    margin-bottom: 2rem;
}

.faq-questions-container {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
}

.faq-question-button {
    @extend .button;
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: #FFF;
    border: 0;
    border-bottom: 1px solid #000;
    padding: 1rem;
    margin-bottom: 1rem;
    font-size: 1.2rem;
    font-weight: 600;
    cursor: pointer;
}

@media (max-width: 576px) {
    .faq-question-button {
        padding: 1rem 0;
    }
  }