.service-swiper {
    width: 120%;
    height: 100%;
}

.service-swiper .swiper-wrapper {
    display: flex;
    align-items: stretch;
    justify-content: stretch;
}

.service-swiper .swiper-slide {
    margin: 1rem 0rem;
    height: auto;
}

.service-swiper .swiper-slide-active {
    margin: 0;
}
