.form-element {
    margin: 1rem 0;

    .form-element-label {
        display: flex; 
        flex-direction: column;
    }
    
    .form-element-title {
        font-size: $formFontSize;
        padding-bottom: 5px;
    }

    .form-element-input {
        padding: 12px;
        border: 1px solid #A9ACB0;
        font-size: $formFontSize;
        font-family: $default-font-family;

        &:focus {
            border-color: #010101;
            outline: none;
        }
    }

    .form-element-invalid {
        color: $main-red;
        font-weight: 500;
    }

    &.isInvalid {
        .form-element-input {
            border-color: $main-red;
        }
    }

    input[type="checkbox"] {
        display: none;
    }

    .agreement-label {
        display: grid;
        grid-template-columns: 2rem auto;
        align-items: center;
        cursor: pointer;
    }

    .form-element-invalid.checkbox {
        margin-left: 2rem;
    }

    .form-element-textarea {
        @extend .form-element-input;
    }
}