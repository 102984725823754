.clients-container {
    padding-top: 3rem;
    padding-bottom: 3rem;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 5rem;
}

.clients-container img {
    width: 100%;
}

@media (max-width: 1200px) {
    .clients-container {
        column-gap: 3rem;
    }
}

@media (max-width: 992px) {
    .clients-container {
        width: 100% !important;
        display: block;
    }
}