.service-navigation-container {
    border-bottom: 1px solid #BEBEBE;
    background-color: #FFF;
    width: 100%;
}

.service-navigation-container-fixed {
    position: fixed;
    top: 0;
    border-top: 1px solid $main-red;
}

.service-navigation {
    display: flex;
    justify-content: space-between;
}

.show-navigation-button {
    @extend .button;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    border: 0;
    width: 120%;
    padding: 1rem;
    background-color: #FFF;
    font-size: 1.25rem;
    font-weight: 600;
}

.show-navigation-button:hover {
    background-color: #CFCFCF;
    transition: all .2s ease;
    -webkit-transition: all .2s ease;
    -moz-transition: all .2s ease;
    -ms-transition: all .2s ease;
    -o-transition: all .2s ease;
}

.pages-navigation {
    position: relative;
}

.pages-select {
    width: 200%;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: -1%;
    z-index: 99;
    background-color: #FFF;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.pages-select a {
    padding: 1rem;
}

.pages-select a:hover {
    background-color: #CFCFCF;
    transition: all .1s ease;
    -webkit-transition: all .1s ease;
    -moz-transition: all .1s ease;
    -ms-transition: all .1s ease;
    -o-transition: all .1s ease;
}

.sections-navigation {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.sections-navigation a {
    padding: 1rem;
    border-bottom: 2px solid transparent;
}

.sections-navigation a:hover {
    color: $text-dark-blue;
    border-bottom: 2px solid $text-dark-blue;
    transition: all .2s ease;
    -webkit-transition: all .2s ease;
    -moz-transition: all .2s ease;
    -ms-transition: all .2s ease;
    -o-transition: all .2s ease;
}

@media (max-width: 992px) {
    .pages-select {
        width: 120%;
    }
    .service-navigation {
        flex-direction: column;
        align-items: center;
    }
    .sections-navigation {
        justify-content: space-between;
        width: 100%;
    }
  }

@media (max-width: 768px) {
    .pages-navigation {
        display: flex;
        justify-content: center;
        width: 100%;
    }
    .pages-select {
        width: 100%;
    }
  }

  @media (max-width: 576px) {
    .sections-navigation a {
        padding: 1rem .3rem;
    }
  }