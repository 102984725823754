.agreement-modal {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.agreement-modal .buttons {
    display: flex;
    justify-content: space-evenly;
}

.agreement-modal .buttons button {
    @extend .button;   
    border: 2px solid transparent;
    cursor: pointer;                                                                                                                                                                                                                                                                                                                                                                                                                                                     ;
    padding: 1rem;
    background-color: $text-dark-blue;
    font-weight: 700;
    color: #FFF;
    transition: all .3s ease;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
}

.agreement-modal .buttons button:hover {
    color: $text-dark-blue;
    background-color: #FFF;
    border: 2px solid $text-dark-blue;
}